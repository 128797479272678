import React from "react";
import Layout from "../component/layout";
import HomeImage from "../images/net-dev-home-image.png";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import BorderCards from "../component/BorderCards";
import Icon from "../images/card-icon.png";
import CardsComponent from "../component/CardsComponent";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import AccordionComponent from "../component/accordionComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import FooterComponent from "../component/footerComponent";

const ServiceNetDev = () => {
  const seo = {
    title: ".NET Development Company in USA | Motomtech",
    metaDesc:
      "Unlock the power of .NET with our skilled team of developers. From web apps to enterprise solutions, we've got you covered.",
  };

  const cards = [
    {
      icon: Icon,
      title: "Custom .NET Web Applications:",
      description:
        "We leverage the .NET framework's robust features to build dynamic, scalable, and high-performance web applications tailored to your specific business needs.",
    },
    {
      icon: Icon,
      title: "Desktop Applications with .NET:",
      description:
        "We specialize in developing sophisticated desktop applications using .NET, delivering unparalleled performance, security, and user experience across various Windows operating systems.",
    },
    {
      icon: Icon,
      title: "Robust Mobile Applications with .NET:",
      description:
        "Utilizing .NET's cross-platform capabilities, we build robust mobile applications that deliver a seamless user experience across various platforms, including Android, iOS, and Windows.",
    },
    {
      icon: Icon,
      title: ".NET Microservices:",
      description:
        "Our .NET Microservices solutions allow businesses to develop scalable and flexible applications that can be easily updated and maintained. This architecture allows for rapid, frequent, and reliable delivery of complex applications.",
    },
    {
      icon: Icon,
      title: ".NET Machine Learning:",
      description:
        "We leverage .NET's machine learning libraries to develop intelligent applications capable of predictive analysis, anomaly detection, and more. These solutions can enhance your business decisions, improve customer service, and give you a competitive edge.",
    },
    {
      icon: Icon,
      title: "Application Lifecycle Management:",
      description:
        "We provide comprehensive Application Lifecycle Management solutions, covering all stages from planning, development, testing, deployment, and maintenance. This ensures your .NET applications are always up-to-date and meet evolving business needs.",
    },
    {
      icon: Icon,
      title: "Portals & Business Applications:",
      description:
        "Our team develops .NET-based enterprise portals and business applications that streamline your business operations, improve internal communication, and foster better collaboration.",
    },
    {
      icon: Icon,
      title: "CRM, POS, CMS Development:",
      description:
        "Leveraging .NET, we build robust Customer Relationship Management (CRM), Point of Sale (POS), and Content Management System (CMS) applications that help streamline your business operations, improve customer engagement, and boost sales.",
    },
    {
      icon: Icon,
      title: "Legacy Application Re-engineering:",
      description:
        "We provide .NET-based legacy application re-engineering services, updating and upgrading your outdated applications to modern standards, enhancing their performance, security, and functionality.",
    },
  ];

  const questions1 = [
    {
      title: "What are the best and most used .NET frameworks?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            When it comes to the most used and best .NET frameworks, the top of
            the list includes ASP.NET, .NET Core, and Xamarin. ASP.NET is widely
            used for developing dynamic and modern web applications with a high
            level of performance and scalability. It supports various
            development models, including MVC, Web API, and Web Pages.
          </p>
          <p className="accordion-section__card-desc">
            .NET Core is an open-source, cross-platform framework for building
            cloud-based, internet-connected applications, such as web apps, IoT
            apps, and mobile backends. It's designed to be platform-agnostic and
            supports microservices architecture.
          </p>
          <p className="accordion-section__card-desc">
            Xamarin is a .NET-based framework used for building cross-platform
            mobile applications. It allows developers to share code across iOS,
            Android, and Windows platforms while delivering native app
            performance.
          </p>
        </>
      ),
      eventKey: 1,
    },
    {
      title: "What is the difference between .NET and .NET Core?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            The .NET Framework and .NET Core are both platforms for building
            applications, but they have some key differences. The .NET Framework
            is a Windows-only platform that has been around since the early
            2000s. It's used for building Windows desktop applications and web
            applications running on Windows servers.
          </p>
          <p className="accordion-section__card-desc">
            On the other hand, .NET Core is a newer, open-source version of .NET
            that runs on multiple platforms, including Windows, macOS, and
            Linux. It's designed for building modern, cloud-based, and
            internet-connected applications. Its cross-platform capabilities
            make it a popular choice for applications that need to run on
            multiple operating systems.
          </p>
        </>
      ),
      eventKey: 2,
    },
  ];

  const questions2 = [
    {
      title: "What kind of applications can you build using Microsoft .NET?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            Microsoft .NET is a versatile framework that allows for the
            development of a wide variety of applications. These include web
            applications, desktop applications, mobile applications (via
            Xamarin), microservices, cloud-based applications, game development
            (via Unity), and even Internet of Things (IoT) applications.
          </p>
          <p className="accordion-section__card-desc">
            Its versatility is due to the broad support for different
            programming languages and the robust class libraries it provides,
            allowing for a high degree of code reuse and interoperability.
            Regardless of the size and complexity of your project, .NET can
            provide a reliable and scalable solution.
          </p>
        </>
      ),
      eventKey: 3,
    },
    {
      title: "How much does an ASP.NET application development cost?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            The cost of developing an ASP.NET application can vary significantly
            based on several factors such as the complexity of the application,
            the number of features, the level of customization required, and the
            expertise of the development team.
          </p>
          <p className="accordion-section__card-desc">
            A simple ASP.NET application with basic features might cost less,
            while a complex enterprise-grade application with advanced
            functionalities like AI or machine learning capabilities, could cost
            significantly more. The best way to estimate the cost is to outline
            your project requirements and discuss them with a reputable .NET
            development company, who can provide a more accurate cost estimate
            based on your specific needs.
          </p>
        </>
      ),
      eventKey: 4,
    },
  ];
  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={".NET Development Company"}
        homeDescription={
          "PAt Motomtech, .NET is more than just a language – it's a catalyst for transformative digital experiences. With a deep understanding of the framework's capabilities, we create cutting-edge solutions that empower businesses to thrive in the digital landscape. Let us be your trusted partner in harnessing the true potential of .NET for your next project."
        }
        homeImage={HomeImage}
        homeButton={"Request our services"}
        isGatsby={false}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={
          "Unleash Your Business Potential with .NET Development Solutions"
        }
        description1={
          "Choosing Motomtech for your .NET development needs is a decision rooted in quality, experience, and innovation. As a leading Python and .NET development company, we have a proven track record of creating bespoke solutions that align perfectly with our clients' objectives and requirements. We recognize the unique characteristics of each business, and we are committed to providing solutions that accentuate their strengths and provide competitive advantages in their respective markets."
        }
        description2={
          "Our diverse pool of professionals brings a wealth of experience in .NET development, having delivered numerous projects across multiple sectors. This experience enables us to navigate the unique challenges that might arise in the course of project execution, thus ensuring your project's seamless progression. Moreover, our proficiency with .NET development is paired with our adeptness in Python, further enhancing our capacity to provide comprehensive solutions that can synergize and augment your business operations."
        }
        description3={
          "Motomtech stands as a stalwart in Python and .NET development outsourcing, and our commitment to our clients' success is unparalleled. We understand that entrusting your .NET development needs to an external entity can be a significant decision. Therefore, we adopt a transparent, collaborative approach to ensure that our clients are always in the loop, informed about project progression, and have their needs and inputs meticulously incorporated."
        }
        description4={
          "Our primary goal is to transform your business ideas into tangible results, and our proven expertise in .NET development makes us the perfect candidate to handle your project. Whether it's developing enterprise-grade applications or crafting custom solutions, Motomtech offers a wide array of .NET development services that can cater to all your specific needs."
        }
        description5={
          "In conclusion, entrusting your .NET development needs to Motomtech is a smart business decision. Our reputation as a reliable Python and Dot Net development company, backed by years of hands-on experience, innovative solutions, and a client-centric approach, ensures that we can deliver top-notch results, thereby catalyzing the growth and success of your business."
        }
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <p className="section-container-title">.NET Development Services</p>
        <BorderCards
          borderCardsText1={"Custom .NET Development:"}
          borderCardsText2={
            "At Motomtech, we specialize in crafting personalized, high-quality .NET solutions that address your unique business needs. With a keen understanding of your objectives, we design and develop applications that deliver superior functionality and foster seamless business operations."
          }
          borderCardsText3={".NET IT Augmentation Services:"}
          borderCardsText4={
            "Our .NET IT Augmentation services enable businesses to scale their development team effortlessly and cost-effectively. We supplement your in-house team with our proficient .NET developers, ensuring that you have the right expertise at your disposal to accelerate project timelines and maintain high development standards."
          }
          borderCardsText5={".NET QA Services:"}
          borderCardsText6={
            "Quality is at the heart of our services. With our .NET QA Services, we assure that your .NET applications meet the highest quality standards. We conduct rigorous testing across multiple stages of development to identify and rectify potential issues, thus delivering flawless solutions that excel in real-world conditions."
          }
          borderCardsText7={".NET Maintenance Services:"}
          borderCardsText8={
            "We understand that software development doesn't end with deployment. Our .NET Maintenance Services are designed to ensure your applications remain up-to-date and functional, providing continuous improvements and upgrades as per the evolving business requirements and market trends."
          }
          borderCardsText9={".NET Consulting:"}
          borderCardsText10={
            "At Motomtech, we don't just build software; we build partnerships. Our .NET Consulting services aim to provide businesses with strategic insights and technical guidance necessary to leverage .NET to its fullest potential. Whether you need help in conceptualizing your project or optimizing your existing .NET applications, our experienced consultants are here to guide you every step of the way."
          }
        />
      </div>
      <ItExpandTeamComponent
        title={"Why Leverage .NET For Your Next Project?"}
        description1={
          "Choosing .NET for your next project can open a realm of benefits, thanks to its versatility, extensive language support, and robust cross-platform development capabilities. It's an open-source developer platform, created by Microsoft, that allows you to build applications for web, mobile, desktop, games, IoT, AI, and more. Unlike other frameworks that may be tied to a single language or platform, .NET supports multiple programming languages like C#, F#, and Visual Basic. This means developers can choose the language that best suits the project requirements, or even use multiple languages in a single project, offering an unparalleled level of flexibility."
        }
        description2={
          "Moreover, .NET's ability to support cross-platform development stands as a significant advantage. With .NET, you can build applications that can run seamlessly on different platforms such as Windows, macOS, and Linux. This eliminates the need to write separate codebases for different operating systems, saving time and resources, while maximizing the reach of your application."
        }
        description3={
          "The .NET framework also excels in terms of seamless integration and productivity boost. It comes equipped with a rich set of class libraries and APIs that developers can leverage to integrate various functionalities in their applications. This, combined with powerful development tools like Visual Studio, significantly reduces the development time and enhances productivity."
        }
        description4={
          "Furthermore, .NET is renowned for its scalability and robust security features. Whether you're building a small business website or an enterprise-grade application, .NET's ability to scale up to match growing business demands ensures that your application remains performant even under increased loads. In terms of security, .NET provides features like authentication, encryption, and secure coding practices to build secure applications, making it a trusted choice for industries like finance and healthcare."
        }
        description5={
          "Lastly, the .NET platform boasts an extensive framework ecosystem and a vibrant community of developers. This means that you will have access to a wide range of tools, libraries, and resources, and an active community that can provide help, share knowledge, and contribute to the continuous improvement of the platform. In conclusion, leveraging .NET for your next project can provide a competitive edge in terms of flexibility, productivity, scalability, and security."
        }
      />
      <CardsComponent
        title={"Outsourced .NET Development Solutions"}
        description={
          "By outsourcing your .NET development needs to us, you can leverage these solutions to enhance your business efficiency, foster innovation, and stay competitive in the rapidly evolving digital landscape.Custom .NET Web Applications: We leverage the .NET framework's robust features to build dynamic, scalable, and high-performance web applications tailored to your specific business needs."
        }
        cards={cards}
      />
      <div style={{ position: "relative", zIndex: "3" }}>
        <TechStackComponent />
      </div>
      <CustomDevelopmentVideoComponent />
      <AccordionComponent
        arr1={questions1}
        arr2={questions2}
        title={"Frequently asked questions"}
        subtitle={"Here you can find some questions that our user ask te most."}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={"Jumpstart your startup with Motomtech"}
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default ServiceNetDev;
